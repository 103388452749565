import React, { Component } from 'react';
import "../styles/blog.css"
import {connect} from "react-redux";
import Author from "../components/Author";

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

async function saveLogs(){
    //get IP
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    //get URL parameters
    const query = new URLSearchParams(window.location.search);
    let session = query.get('session')
    if(session == null){
        session = "direct";
    }
    //PageID
    const pathname = window.location.pathname;
    const pageID = pathname.replace("/", "");
    //logs
    const requestOptionsLogs = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ip: data.IPv4,
            country_code: data.country_code,
            country_name: data.country_name,
            state: data.state,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
            postal: data.postal,
            page: "blog",
            session: session,
            event: "openPage",
            element: "page",
            elementID: "blog:" + pageID
        })
    };
    await fetch(API_URL + '/log/save', requestOptionsLogs);

    //Remove the success parameter from the URL using replaceState
    const urlWithoutSuccess = window.location.pathname + window.location.search.replace('?session=' + session, '');
    window.history.replaceState({}, document.title, urlWithoutSuccess);
}

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            title: "",
            mainImage: "",
            text: "",
            tags: "",
            subject: ""
        };
    }

    async componentDidMount() {
        const pathname = window.location.pathname;
        const pageID = pathname.replace("/", "");

        fetch(API_URL + "/doc/blog/" + pageID)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    id: data.id,
                    title: data.title,
                    mainImage: data.mainImage,
                    text: data.text,
                    tags: data.tags,
                    subject: data.subject
                });
            });

        //logs
        await saveLogs();
    }

    render() {
        return (
            <div className="blog-container">
                <div className="blog-wrap">
                    <div className="blog-title">
                        {this.state.title}
                    </div>
                    <div className="blog-tags">
                        <div className="blog-tags-list">
                            {this.state.tags}
                        </div>
                        <div className="blog-tags-info">
                            Автор:
                        </div>
                        <div className="blog-tags-name">
                            Юлия Новосёлова
                        </div>
                    </div>
                    <div className="blog-mainImageDiv">
                        <img className="blog-mainImage" id={this.state.id} src={this.state.mainImage}/>
                    </div>
                    <Author page="blog"/>
                    <div className="blog-textDiv">
                        <div className="blog-text" dangerouslySetInnerHTML={ { __html: this.state.text} }/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Blog);