import React from "react";

const TagsList = () => {
    return(
        <div id="main-slider-container-tags">
            <div className="slider-tag" id="tagList">
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "julia"}>
                    <p className="slider-card-title-tags">Психология</p>
                </div>
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "page?tag=relations"}>
                    <p className="slider-card-title-tags">Отношения</p>
                </div>
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "page?tag=antistress"}>
                    <p className="slider-card-title-tags">Антистресс</p>
                </div>
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "page?tag=crises"}>
                    <p className="slider-card-title-tags">Кризисы</p>
                </div>
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "page?tag=motivation"}>
                    <p className="slider-card-title-tags">Мотивация</p>
                </div>
                <div className="slider-card-tags" key="1" onClick={()=>window.location = "page?tag=emigration"}>
                    <p className="slider-card-title-tags">Эмиграция</p>
                </div>
            </div>
        </div>
    )
}
export default TagsList;