import React, { Component } from "react";
import { connect } from "react-redux";
import ListOfDocs from "../components/admin/ListOfDocs";
import "../styles/admin/admin.css"
import { logout } from "../actions/auth"

class Admin extends Component{
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
    }

    logOut(e) {
        const { dispatch } = this.props;

        dispatch(logout())
            .then(() => {
                window.location = "/login";
            })
            .catch(() => {
                alert("Error")
            });
    }

    goToWritePage(e){
        window.location = "/write?pageID=new";
    }

    render() {
        const { user: currentUser } = this.props;
        if (!currentUser) {
            window.location = "/login";
        }

        return (
            <React.Fragment>
                <div className="admin">
                    <a className="admin-a" href="#" onClick={this.logOut}>
                        Выход
                    </a>
                    <br/>
                    <input type="submit"
                           value="Написать пост"
                           onClick={this.goToWritePage}
                           className="admin-btnOrange"
                    />
                </div>
                <ListOfDocs />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}
export default connect(mapStateToProps)(Admin);