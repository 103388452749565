import React, { Component } from "react";
import { connect } from "react-redux";
import WriteDoc from "../components/admin/WriteDoc";

class Write extends Component{
    render() {
        const { user: currentUser } = this.props;
        if (!currentUser) {
            window.location = "/login";
        }

        const pathname = window.location.search.substr(1);
        const pageID = pathname.replace("pageID=", "");

        return (
            <React.Fragment>
                <WriteDoc id={pageID}/>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}
export default connect(mapStateToProps)(Write);