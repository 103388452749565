import React, { Component } from 'react';
import CardGrid from "../components/CardGrid";
import Label from "../components/Label";
import {connect} from "react-redux";

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docs: [],
            id: "",
            text: ""
        }
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        let tag = query.get('tag');
        let text = "";
        if(tag == "motivation"){
            text = "Мотивация";
        } else if (tag == "antistress"){
            text = "Антистресс";
        } else if (tag == "relations"){
            text = "Отношения";
        } else if (tag == "crises"){
            text = "Кризисы";
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: tag })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    docs: data,
                    id: tag,
                    text: text
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <Label id={this.state.id} text={this.state.text} showRightSide="No"/>
                <CardGrid slides={this.state.docs} id="slider1"/>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Tags);