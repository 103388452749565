import React, { Component } from 'react';
import "../styles/profile.css"
import {connect} from "react-redux";
import {BsFacebook, BsInstagram, BsTelegram, BsWhatsapp, BsPhone, BsPaypal,BsCreditCard2Back} from "react-icons/bs";
import {FaRubleSign, FaSkype} from "react-icons/fa";
import { VscCircleFilled } from "react-icons/vsc";
import { SiZoom, SiGooglemeet } from "react-icons/si";

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

async function saveLogs(){
    //get IP
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    //get URL parameters
    const query = new URLSearchParams(window.location.search);
    let session = query.get('session')
    if(session == null){
        session = "direct";
    }
    //logs
    const requestOptionsLogs = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ip: data.IPv4,
            country_code: data.country_code,
            country_name: data.country_name,
            state: data.state,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
            postal: data.postal,
            page: "profile",
            session: session,
            event: "openPage",
            element: "page",
            elementID: "profile:Julia"
        })
    };
    await fetch(API_URL + '/log/save', requestOptionsLogs);

    //Remove the success parameter from the URL using replaceState
    const urlWithoutSuccess = window.location.pathname + window.location.search.replace('?session=' + session, '');
    window.history.replaceState({}, document.title, urlWithoutSuccess);
}

class Profile extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        //logs
        await saveLogs();
    }

    saveClient () {
        const name = document.getElementById('name');
        const contact = document.getElementById('contact');
        const saveClientButton = document.getElementById('saveClientButton');
        const showSuccessResult = document.getElementById('showSuccessResult');

        if(name.value == ''){
            name.style.borderColor = "red";
            return;
        }else {
            name.style.borderColor = "#ccc";
        }

        if(contact.value == ''){
            contact.style.borderColor = "red";
            return;
        }else {
            contact.style.borderColor = "#ccc";
        }

        const requestOptions = {
            method: 'GET'
        };

        fetch(API_URL + "/profile/saveClient?userName=" + name.value + "&contact=" + contact.value, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data == true){
                    name.style.display = 'none';
                    contact.style.display = 'none';
                    saveClientButton.style.display = 'none';
                    showSuccessResult.style.display = 'block';
                }else {
                    name.style.borderColor = "red";
                    contact.style.borderColor = "red";
                }
            });
    }

    render() {
        return (
            <div>
                <div className="profile-container">
                    <div className="profile-wrap">
                        <div className="profile-textDiv">
                            <div className="profile-text">
                                <div className="profile-text-title">
                                    Привет!
                                </div>
                                <p>Меня зовут <a className="profile-text-a" href="https://www.instagram.com/movieteka" target="_blank">Юлия Новосёлова</a>
                                &nbsp;, я практикующий психолог, психоаналитический терапевт. Живу я в Монреале, а консультирую онлайн по всему миру.</p>
                                <p>Я - магистр кино и психологии, автор статей о кино и психологии в разных изданиях. Получила высшее образование в НИУ ВШЭ на российско-французской магистерской программе «Психоанализ и психоаналитическая психотерапия». Постоянно повышаю квалификацию на различных международных программах. Регулярно прохожу супервизии у членов/кандидатов Международных психоаналитических ассоциаций. </p>
                                <p>Я работаю в психоаналитическом подходе, который ценю как метод и люблю как философию. За глубину и бережность. За плодотворность и витальность. Психоанализ предлагает странствие вглубь себя, чтобы встретиться с самим собой.</p>
                                <p>В этом путешествии я не ставлю диагнозов и не даю советов; и не учу как правильно жить и как бойко действовать. Так что же я тогда делаю?</p>
                                <p>Я, будто гид, сопровождаю человека. Не указываю маршрут и скорость движения. А даю достаточно надежную опору за счет выстраивания терапевтического альянса. Так, человек, приходит к психоаналитичному терапевту в поисках себя, а находит ещё и новый опыт взаимоотношений. С собой, с терапевтом, с окружающими, с миром.</p>
                                <p>Основательница и главред <a className="profile-text-a" href="https://movieteka.com/">movieteka.com</a></p>
                                <p className="profile-text-p"><a className="profile-btn-main" href="#paymentform">Получить консультацию</a></p>
                            </div>
                            <div className="profile-img"><br/>
                                <img src="./img/Julia.jpg" width="290px"/>
                            </div>
                        </div>
                        <div className="profile-topDiv">
                            <div className="profile-col">
                                <div className="profile-col-text">
                                    <a className="profile-col-a" href="https://www.psychologies.ru/wellbeing/8-filmov-o-tom-est-li-zhizn-posle-izmeny/?fbclid=IwAR2sjpztZKGX78w1LkfINVdvPuLCFdrYGbSKnLv_aL94XSFO5hV2YixH7p8" target="_blank">PSYCHOLOGIES</a>
                                </div>
                                <hr className="profile-hr"/>
                                Постоянный эксперт журнала
                            </div>
                            <div className="profile-col">
                                <div className="profile-col-text">
                                    <a className="profile-col-a" href="https://yasno.live/blog/how-parenthood-changes-us?fbclid=IwAR2lxSxqkBX4DhoHIxy-UgAJLOIFApIVMfFxOoG9ns52FkbhYkaf4_M3Tr8" target="_blank">ЯСНО</a>
                                </div>
                                <hr className="profile-hr"/>
                                Постоянный автор блога
                            </div>
                            <div className="profile-col">
                                <div className="profile-col-text">
                                    <a className="profile-col-a" href="https://www.instagram.com/movieteka" target="_blank">INSTAGRAM</a>
                                </div>
                                <hr className="profile-hr"/>
                                Психолог в Канаде
                            </div>
                        </div>

                        <div className="profile-topicDiv">
                            <div className="profile-topicDiv-sub">
                                <div className="profile-topic-title">
                                    ТЕМЫ, С КОТОРЫМИ Я РАБОТАЮ
                                </div>
                                <div className="profile-topic-text">
                                    <p>
                                        <VscCircleFilled size={17} className="circle-icon"/>
                                        &nbsp;
                                        Эмоциональная сфера: депрессия, тревожность, раздражительность, одиночество
                                    </p>
                                    <p>
                                        <VscCircleFilled size={17} className="circle-icon"/>
                                        &nbsp;
                                        Ощущение себя: низкая самооценка и поиск самореализации
                                    </p>
                                    <p>
                                        <VscCircleFilled size={17} className="circle-icon"/>
                                        &nbsp;
                                        Понимание себя: своих ценностей, желаний и чувств
                                    </p>
                                    <p>
                                        <VscCircleFilled size={17} className="circle-icon"/>
                                        &nbsp;
                                        Адаптация в эмиграции: до переезда, в процессе переезда
                                    </p>
                                    <p>
                                        <VscCircleFilled size={17} className="circle-icon"/>
                                        &nbsp;
                                        Кризис идентичности из-за переезда: сопровождаю людей, потерявших ответы на вопросы:<br/>
                                        «Кто я теперь? Где я теперь? На что я могу надеяться?»
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="profile-priceDiv">
                            <div className="profile-payDiv-sub">
                                <div id="paymentform" className="profile-pay-title">
                                    ЗАПИСАТЬСЯ НА КОНСУЛЬТАЦИЮ
                                </div>
                                <div className="profile-pay-text">
                                    <div className="profile-price-form">
                                        <div className="profile-author-description-price-time">
                                            <div className="profile-author-description-price-time-count">
                                                ~ 50 мин онлайн консультации по
                                            </div>
                                            <div className="profile-author-description-price-time-icons-zoom">
                                                <SiZoom size={45} className="contact-icon"/>
                                            </div>
                                            <div className="profile-author-description-price-time-icons-skype">
                                                <FaSkype size={30} className="contact-icon"/>
                                            </div>
                                            <div className="profile-author-description-price-time-icons-meet">
                                                <SiGooglemeet size={29} className="contact-icon"/>
                                            </div>
                                        </div>
                                        <div className="profile-author-description-price-count">
                                            5000
                                            <FaRubleSign className="profile-author-description-price-icon"/>
                                            / 50$
                                        </div>
                                        <div className="profile-author-description-price-payment-methods">
                                            <BsPhone className="payment-icon"/>
                                            <BsCreditCard2Back className="payment-icon"/>
                                            <BsPaypal className="payment-icon"/>
                                        </div>
                                        <div className="profile-author-button">
                                            <div className="profile-inputTextWrite-div">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="profile-inputTextWrite"
                                                    placeholder="Введите ваше имя"
                                                />
                                            </div>
                                            <div className="profile-inputTextWrite-div">
                                                <input
                                                    type="text"
                                                    id="contact"
                                                    name="contact"
                                                    className="profile-inputTextWrite"
                                                    placeholder="Введите ваш телефон или email"
                                                />
                                            </div>
                                            <div className="profile-btn-div">
                                                <a
                                                    id="saveClientButton"
                                                    className="profile-btn"
                                                    onClick={this.saveClient}>Записаться</a>
                                            </div>
                                            <p id="showSuccessResult" className="profile-showSuccessResult">
                                                Ваша заявка принята. Мы свяжемся с вами в ближайщее время. Спасибо!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-contactsDiv">
                            <div className="profile-contactsDiv-sub">
                                <div className="profile-contacts-title">
                                    КОНТАКТЫ
                                </div>
                                <div className="profile-contacts-text">
                                    <div className="profile-social-col">
                                        <a href="https://t.me/movieteka" target="_blank">
                                            <BsTelegram className="profile-contact-icon"/>
                                        </a>
                                    </div>
                                    <div className="profile-social-col">
                                        <a href="https://wa.me/+15145926450" target="_blank">
                                            <BsWhatsapp className="profile-contact-icon"/>
                                        </a>
                                    </div>
                                    <div className="profile-social-col">
                                        <a href="https://www.facebook.com/movietekacom" target="_blank">
                                            <BsFacebook className="profile-contact-icon"/>
                                        </a>
                                    </div>
                                    <div className="profile-social-col">
                                        <a href="https://www.instagram.com/movieteka" target="_blank">
                                            <BsInstagram className="profile-contact-icon"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Profile);