import React from "react";
import {MdChevronLeft, MdChevronRight} from "react-icons/md";
import "../styles/slider.css";

const CardSlider = (props) => {
    return(
        <div className="main-grid-container">
            <div className="main-grid-container-row">
                {
                    props.slides.map((slide, index)=>{
                        return(
                            <div className="grid-card " key={index} onClick={()=>window.location = "/" + slide.id}>
                                <div className="slider-card-image" style={{backgroundImage:`url(${slide.mainImage})`, backgroundSize:'cover'}}></div>
                                <p className="slider-card-title">{slide.title}</p>
                                <p className="slider-card-description">{slide.tags}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default CardSlider;