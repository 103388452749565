import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Profile from "./pages/Profile";
import Tags from "./pages/Tags";
import WriteDoc from "./pages/Write";
import NoPage from "./pages/NoPage";
import Admin from "./pages/Admin";
import LoginComponent from "./components/Login";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: undefined
        };
    }

    componentDidMount() {
        const user = this.props.user;

        if (user) {
            this.setState({
                currentUser: user
            });
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />} >
                        <Route index element={<Home />} />
                        <Route path="/login" element={<LoginComponent />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/write" element={<WriteDoc />} />
                        <Route path="/julia" element={<Profile />} />
                        <Route path="/page" element={<Tags />}/>
                        <Route path=":pageID" element={<Blog />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(App);