import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import "../styles/admin/login.css"

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    const { dispatch } = this.props;

    dispatch(login(this.state.username, this.state.password))
    .then(() => {
      window.location = "/admin";
    })
    .catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  render() {
    return (
      <div className="login">
        <form
          onSubmit={this.handleLogin}
          ref={c => {
            this.form = c;
          }}>
          <div className="login-form-group">
            <input
              type="text"
              name="username"
              value={this.state.username}
              onChange={this.onChangeUsername}
              className="login-inputTextWrite"
              placeholder="User"
            />
          </div>
          <div className="login-form-group">
            <input
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.onChangePassword}
              className="login-inputTextWrite"
              placeholder="Password"
            />
          </div>

          <div className="login-form-group-button">
            <button
              className="login-btnOrange"
              value="Login">
              <span>Вход</span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Login);
