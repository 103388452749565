import React, { Component } from 'react';
import { connect } from "react-redux";
import "../../styles/admin/listOfDocs.css"
import Select from "react-select";

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

class ListOfDocs extends Component{
    constructor(props) {
        super(props);

        this.showMorePosts = this.showMorePosts.bind(this);
        this.onChangeVisible = this.onChangeVisible.bind(this);

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.user.accessToken },
            body: JSON.stringify({ pageNo: 0, pageSize: 20, subject: "all" })
        };

        fetch(API_URL+ '/doc/docs', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    items: data
                });
            });
    }

    showMorePosts(e) {
        const page = e.target.value - 1;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.user.accessToken },
            body: JSON.stringify({ pageNo: page, pageSize: 20, subject: "all" })
        };

        fetch(API_URL+ '/doc/docs', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    items: data
                });
            });
    }

    onChangeVisible(id, e) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.user.accessToken },
            body: JSON.stringify({ id: id, visible: e.value })
        };

        fetch(API_URL + '/write/saveVisible', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState(state => {
                    const found = state.items.find(obj => {
                        return obj.id === id;
                    });
                    found.visible = e.value;
                    this.forceUpdate();
                });
            });
    }

    render() {
        const { user: currentUser } = this.props;
        if (!currentUser) {
            window.location = "/login";
        }
        const { items } = this.state;

        const options = [
            { value: 'visible', label: 'visible' },
            { value: 'waiting', label: 'waiting' },
            { value: 'deleted', label: 'deleted' }
        ]

        return (
            <div className="listOfDocs">
                <div className="listOfDocs-main">
                    Посты
                </div>
                {
                items.map((item) => (
                    <ol key = { item.id } >
                        <div className="listOfDocs-title">
                            {item.id + ". " + item.title}
                        </div>

                        <div className="listOfDocs-line-settings">
                            <div className="listOfDocs-div-edit">
                                <a className="listOfDocs-a" href={"write?pageID=" + item.id}>Редактировать</a>
                            </div>
                            <div className="listOfDocs-div-select">
                                <Select
                                    className="listOfDocs-select"
                                    options={options}
                                    onChange={event  => this.onChangeVisible(item.id, event)}
                                    value={{ value: item.visible, label: item.visible }}
                                />
                            </div>
                        </div>
                    </ol>
                ))
                }
                <br/><hr/>
                <div className="listOfDocs-line-page">
                    <label>Страница </label>
                    <input type="number"
                           min="1"
                           defaultValue="1"
                           className="listOfDocs-inputTextAdminCount"
                           id="page"
                           name="page"
                           onChange={this.showMorePosts}
                           placeholder="page"/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(ListOfDocs);