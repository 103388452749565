import React from "react";
import {MdChevronLeft, MdChevronRight} from "react-icons/md";
import "../styles/slider.css";

const CardSlider = (props) => {
    const sliderLeft =()=> {
        var slider = document.getElementById(props.id);
        slider.scrollLeft = slider.scrollLeft - 500;
    }

    const sliderRight =()=> {
        var slider = document.getElementById(props.id);
        slider.scrollLeft = slider.scrollLeft + 500;
    }

    return(
        <div id="main-slider-container">
            <MdChevronLeft size={40} className="slider-icon left" onClick={sliderLeft}/>
            <div className="slider" id={props.id}>
                {
                    props.slides.map((slide, index)=>{
                        return(
                            <div className="slider-card" key={index} onClick={()=>window.location = "/" + slide.id}>
                                <div className="slider-card-image" style={{backgroundImage:`url(${slide.mainImage})`, backgroundSize:'cover'}}></div>
                                <p className="slider-card-title">{slide.title}</p>
                                <p className="slider-card-description">{slide.tags}</p>
                            </div>
                        )
                    })
                }
            </div>
            <MdChevronRight size={40} className="slider-icon right" onClick={sliderRight}/>
        </div>
    )
}
export default CardSlider;