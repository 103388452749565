import React from "react";

const Label = (props) => {
    return(
        <div className="label">
            <div className="labelLeft">
                <h2><a className="labelA" href={"/page?tag=" + props.id}>{props.text}</a></h2>
            </div>
        </div>
    )
}

export default Label;