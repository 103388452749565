import React from "react";
import { FaRubleSign } from "react-icons/fa";
import {BsTelegram} from "react-icons/bs";
import {BsWhatsapp} from "react-icons/bs";
import {BsFacebook} from "react-icons/bs";
import {BsInstagram} from "react-icons/bs";

const Author = (props) => {
    return(
        <div className={props.page + "-author-container"}>
            <div className={props.page + "-author"}>
                <div className="home-author-img">
                    <img src="./img/JuliaNovoselova.png" width="100%"/>
                </div>
                <div className="home-author-description">
                    <div className="home-author-description-price">
                        <div className="home-author-img-mobile">
                            <img src="./img/JuliaNovoselova.png" width="100%"/>
                        </div>
                        <div className="home-author-description-price-block">
                            <div className="home-author-description-price-time">~ 50 мин от </div>
                            <div className={props.page + "-author-description-price-count"}>
                                5000
                                <FaRubleSign className={props.page + "-author-description-price-icon"}/>
                                / 50$
                            </div>
                        </div>
                    </div>
                    <div className="home-author-description-author">
                        ПСИХОЛОГ
                    </div>
                    <div className={props.page + "-author-description-name"}>
                        <b>Юлия Новосёлова</b>
                    </div>
                    <div className="home-author-description-info">
                        <div className="home-author-description-info-desc">
                            Основатель и главный редактор movieteka.com
                        </div>
                        <div className="home-author-description-info-pay">
                            <a className="author-btn" href={"/julia?session=" + props.page}>Записаться</a>
                        </div>
                    </div>
                    <div className="home-author-description-contact">
                        <a href="https://t.me/movieteka" target="_blank">
                            <BsTelegram size={35} className="home-author-description-contact-icon"/>
                        </a>
                        <a href="https://wa.me/+15145926450" target="_blank">
                            <BsWhatsapp size={35} className="home-author-description-contact-icon"/>
                        </a>
                        <a href="https://www.facebook.com/movietekacom" target="_blank">
                            <BsFacebook size={35} className="home-author-description-contact-icon"/>
                        </a>
                        <a href="https://www.instagram.com/movieteka" target="_blank">
                            <BsInstagram size={35} className="home-author-description-contact-icon"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;