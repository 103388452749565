import React, { Component } from 'react';
import {FaBars, FaTimes, FaRunning} from "react-icons/fa";
import "../styles/navBar.css"
import { AiFillHome } from "react-icons/ai";
import {BsPeopleFill, BsFillArrowThroughHeartFill, BsGraphDown, BsFillLightbulbFill} from "react-icons/bs";
import {GiEarthAmerica} from "react-icons/gi";

class Navbar extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.divRef = React.createRef();
        this.setState({});
    }

    render() {
        const showNavBar = () => {
            this.divRef.current.classList.toggle("responsive_nav");
        }

        return (
            <header>
                <h3><a href="/">movieteka.com</a></h3>
                <nav ref={this.divRef}>
                    <a href="/">
                        <AiFillHome size={15} className="nav-icon"/>
                        Главная
                    </a>
                    <a href="julia">
                        <BsPeopleFill size={15} className="nav-icon"/>
                        Психология
                    </a>
                    <a href="page?tag=relations">
                        <BsFillArrowThroughHeartFill size={15} className="nav-icon"/>
                        Отношения
                    </a>
                    <a href="page?tag=antistress">
                        <BsFillLightbulbFill size={15} className="nav-icon"/>
                        Антистресс
                    </a>
                    <a href="page?tag=crises">
                        <BsGraphDown size={15} className="nav-icon"/>
                        Кризисы
                    </a>
                    <a href="page?tag=motivation">
                        <FaRunning size={16} className="nav-icon"/>
                        Мотивация
                    </a>
                    <a href="page?tag=emigration">
                        <GiEarthAmerica size={18} className="nav-icon"/>
                        Эмиграция
                    </a>
                    <button className="nav-btn nav-close-btn" onClick={showNavBar}>
                        <FaTimes/>
                    </button>
                </nav>
                <button className="nav-btn" onClick={showNavBar}>
                    <FaBars />
                </button>
            </header>
        );
    }
}

export default Navbar;